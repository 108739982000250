import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Hooks
import useSiteMetadata from '../../../hooks/useSiteMetadataQuery'

// Local
import ShareLink from './ShareLink'

// Icons
import IconFacebook from '../../../assets/img/share/facebook.svg'
import IconLinkedIn from '../../../assets/img/share/linkedin.svg'
import IconEmail from '../../../assets/img/share/email.svg'
import IconLink from '../../../assets/img/share/link.svg'

const ShareLinks = (props) => {
  const { className, title, url } = props

  const { site: { siteMetadata } } = useSiteMetadata()

  // Generate the absolute URL based on the site metadata
  const absoluteUrl = `${siteMetadata.siteUrl}${url}`

  const platforms = props.platforms || [{
    name: 'Facebook',
    url: `https://www.facebook.com/sharer.php?t=${title}&u=${absoluteUrl}`,
    icon: <IconFacebook className='w-6' />,
    type: 'popup'
  }, {
    name: 'LinkedIn',
    url: `https://www.linkedin.com/sharing/share-offsite/?url=${absoluteUrl}`,
    icon: <IconLinkedIn className='w-6' />,
    type: 'popup'
  }, {
    name: 'Email',
    url: `mailto:?body=${title}\n${absoluteUrl}`,
    icon: <IconEmail className='w-6' />,
    type: 'default'
  }, {
    name: 'Link',
    url: absoluteUrl,
    icon: <IconLink className='w-6' />,
    type: 'copy'
  }]

  return (
    <ul className={classNames('flex justify-center space-x-2', className)}>
      {platforms.map((platform, platformIndex) => {
        return (
          <li key={platformIndex}>
            <ShareLink
              {...platform}
            />
          </li>
        )
      })}
    </ul>
  )
}

ShareLinks.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  platforms: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.element,
    type: PropTypes.string.isRequired
  }))
}

export default ShareLinks
