import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Generic
import HtmlContent from '../HtmlContent/HtmlContent'
import Image from '../Image/Image'

/**
 * Get the thumbnail of the Embed, if a custom image is passed, or if one can
 * be pulled from the Embed API
 * @param  {Object}      embed
 * @param  {Object|null} customThumbnail
 * @return {Object|null}
 */
const getThumbnail = (embed, customThumbnail) => {
  if (customThumbnail) {
    return customThumbnail
  }

  // If no custom thumbnail is provided and one is available from the embed
  if (!customThumbnail && embed.thumbnail_url) {
    // Generate a decent sized thumbnail instead of the tiny one provided via the
    // platform API
    // `856` is the widest size on the site where the media will be shown
    const desiredWidth = 856
    const desiredHeight = Math.round((embed.height / embed.width) * desiredWidth)

    return {
      url: embed.thumbnail_url.replace(/\d+x\d+/gi, `${desiredWidth}x${desiredHeight}`),
      dimensions: {
        height: desiredHeight,
        width: desiredWidth
      }
    }
  }

  return null
}

/**
 * Update the HTML for the Embed, as sometimes we might want to add an autoplay
 * param to the URL
 * @param  {Object}      embed
 * @param  {Object|null} thumbnail
 * @return {String}
 */
const updateIframeSrc = (embed, thumbnail) => {
  // If the HTML doesn't include an iframe, do nothing. It'll probably include
  // a script tag instead, which we can't do much with
  // We don't want to autoplay any iframes which don't have a thumbnail, so
  // don't make any changes if that's the case either
  if (!embed.html?.includes('<iframe') || !thumbnail) {
    return embed.html
  }

  // Update the `src` of the iframe so that `autoplay=1` is appended to the end
  // Pull out the current `src` value
  const iframeSrc = embed.html.match(/<iframe.*src="(.*?)"/)[1]
  let autoplayIframeSrc = ''

  // Check if a query string is already appended
  if (iframeSrc.includes('?')) {
    // If so, append to the query string
    autoplayIframeSrc = `${iframeSrc}&autoplay=1`
  } else {
    // if not, add as a query string
    autoplayIframeSrc += `${iframeSrc}?autoplay=1`
  }

  // Replace the src in the HTML
  return embed.html.replace(iframeSrc, autoplayIframeSrc)
}

const EmbedVideo = (props) => {
  const { embed, className, imageClassName, title, thumbnail: customThumbnail } = props
  const [isPlaying, setIsPlaying] = useState(false)

  /**
   * Handles the click event of the `Play` CTA and updates the state
   * @param  {Object} event
   * @return {Void}
   */
  const handleClick = (event) => {
    event.preventDefault()

    setIsPlaying(true)
  }

  // Get the thumbnail if the config allows it
  const thumbnail = getThumbnail(embed, customThumbnail)

  // Get the embedHtml with any changes
  const embedHtml = updateIframeSrc(embed, thumbnail)

  return (
    <>
      {(!isPlaying && thumbnail) ? (
        <div className={classNames('relative', className)}>
          <Image
            className={imageClassName}
            fallbackAlt={title || embed.title}
            {...thumbnail}
          />
          <div className='absolute bottom-0 left-0 z-10 w-full h-full bg-gradient-to-t from-black via-transparent' />
          <div className='flex items-center absolute bottom-0 left-0 z-10 mb-6 ml-6 space-x-4'>
            <button
              aria-label='Play Video'
              className='flex justify-center items-center w-16 h-16 bg-white rounded-full'
              onClick={handleClick}
            >
              <span className='text-primary text-xl'>
                <span className='block triangle-right' />
              </span>
            </button>
            {(title || embed.title) && <p className='text-white font-medium'>{title || embed.title}</p>}
          </div>
        </div>
      ) : (
        <HtmlContent
          html={embedHtml}
          className='w-full bg-white c-responsive-iframe'
          style={{ paddingTop: `${(embed.height / embed.width) * 100}%` }}
        />
      )}
    </>
  )
}

EmbedVideo.propTypes = {
  embed: PropTypes.object.isRequired,
  thumbnail: PropTypes.object,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  title: PropTypes.string,
}

export default EmbedVideo
