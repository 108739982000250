import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Generic
import Container from '../Container/Container'
import Heading from '../Heading/Heading'
import Edge from '../Edge/Edge'

// Local
import ArticleGrid from './ArticleGrid'

const ArticleGridWithTitle = (props) => {
  const { articles, className, title, singleRow, hasBackgroundAndTopEdge } = props

  return (
    <section
      className={classNames(className, {
        'bg-offwhite': hasBackgroundAndTopEdge,
        'mb-32': !hasBackgroundAndTopEdge
      })}
    >
      {hasBackgroundAndTopEdge && (
        <Edge
          location='top'
          direction='right'
          outerClassName='text-white'
          innerClassName='text-offwhite'
        />
      )}
      <Container
        className={classNames({
          'pt-10 pb-24': hasBackgroundAndTopEdge
        })}
      >
        <Heading
          className='h4 mb-8 sm:mb-12 text-left'
          {...title}
        />
        <ArticleGrid
          articles={articles}
          singleRow={singleRow}
        />
      </Container>
    </section>
  )
}

ArticleGridWithTitle.propTypes = {
  articles: PropTypes.array.isRequired,
  className: PropTypes.string,
  title: PropTypes.object.isRequired,
  singleRow: PropTypes.bool,
  hasBackgroundAndTopEdge: PropTypes.bool
}

export default ArticleGridWithTitle
