import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

/**
 * "heading elements should follow a logical, numerical order that reflects the
 * structure of the content"
 * This component gets the heading element (h1, h2, etc) from the CMS, and
 * allows us to use the correct markup, but we pass another class (.h2, .h3)
 * which will render it at the correct size
 * @param  {Object} props
 * @return {Element}
 */
const Heading = (props) => {
  const { className, raw, id } = props;

  if (!raw[0]) {
    console.error('raw data not passed to Header component')
    return null
  }

  // A common value here would be `heading2`, but we just want `h2`, so remove
  // the bit in the middle
  const Element = raw[0].type.replace('eading', '')

  return (
    <Element className={className} id={id}>
      {raw[0].text.split('\n').map((item, key) => {
        return <Fragment key={key}>{item}<br /></Fragment>
      })}
    </Element>
  )
}

Heading.propTypes = {
  className: PropTypes.string.isRequired,
  raw: PropTypes.array.isRequired,
  id: PropTypes.string,
}

Heading.defaultProps = {
  id: '',
}

export default Heading
